export default {
  data() {
    return {
      timerPhone: '',
      updateTime: null,
    };
  },
  computed: {
    // phoneInfo() {
    //   return this.$store.getters['moduleProfile/need_phone_validate'];
    // },
  },
  beforeDestroy() {
    this.resetTimers();
  },
  methods: {
    updatePhoneInfo(needUpdateList = false) {
      // const oldState = this.phoneInfo;
      if (!this.updateTime) this.updateTime = Date.now();
      this.timerPhone = setTimeout(() => {
        if (needUpdateList) {
          this.$store.dispatch('moduleBilling/modulePayment/updateList', { silent: true });
        }
        this.$store.dispatch('moduleProfile/setProfileInfo').then(data => {
          const curTime = Date.now();
          // console.log(data.need_phone_validate);
          const phoneValidate = data.need_phone_validate === 'true';
          // TIMEOUT = 5 minutes
          const TIMEOUT = 1000 * 60 * 5;
          const needCheck = curTime - this.updateTime < TIMEOUT;
          if (phoneValidate && needCheck) {
            // console.log(phoneValidate && needCheck, phoneValidate, needCheck);
            this.updatePhoneInfo(needUpdateList);
          } else {
            clearTimeout(this.timerPhone);
          }
        });
      }, 1000);
    },
    resetTimers() {
      this.updateTime = null;
      if (this.timerPhone) clearTimeout(this.timerPhone);
    },
  },
};
