var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-card',{staticClass:"tariff-card",scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.tariff.status.key === 'active')?_c('router-link',{staticClass:"tariff-card__title-high big-title",attrs:{"to":{ name: 'tariffStack', params: { id: _vm.tariff.id } }}},[_vm._v(" "+_vm._s(_vm.tariff.title + ' #' + _vm.tariff.id)+" ")]):_c('div',{staticClass:"tariff-card__title-off big-title"},[_vm._v(" "+_vm._s(_vm.tariff.title + ' #' + _vm.tariff.id)+" ")])]},proxy:true},{key:"headerAction",fn:function(){return undefined},proxy:true},{key:"headerEnd",fn:function(){return [_c('main-label',{staticClass:"tariff-card__label",attrs:{"color":_vm.tariff.status.color}},[_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.tariff.status.key}`))+" ")])]},proxy:true}])},[(_vm.isActive === false && _vm.tariff.status.code !== 1)?_c('div',{staticClass:"tariff-card__description"},[_c('div',{staticClass:"tariff-card__title medium-title"},[_vm._v(" "+_vm._s(_vm.tariff.description_os)+" ")]),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.tariff.description_os ? _vm.$t('change') : _vm.$t('add'),
        // placement: 'bottom-start',
        placement: _vm.tariff.description_os ? 'top-end' : 'right-end',
        container: 'body',
      }),expression:"{\n        content: tariff.description_os ? $t('change') : $t('add'),\n        // placement: 'bottom-start',\n        placement: tariff.description_os ? 'top-end' : 'right-end',\n        container: 'body',\n      }"}],class:_vm.tariff.description_os ? 'tariff-card__description-btn' : '',attrs:{"icon":_vm.icon,"size":"medium","color":"dim","tabindex":"-1"},on:{"click":function($event){_vm.isActive = true}}})],1):(_vm.isActive === true)?_c('div',{staticClass:"tariff-card__edit"},[_c('InputText',{staticClass:"p-inputtext-sm",attrs:{"type":"text","autofocus":"","focus":""},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}}),_c('Button',{staticClass:"p-button-success",attrs:{"icon":"pi pi-check","disabled":_vm.newDescription === _vm.tariff.description_os},on:{"click":function($event){return _vm.updateDescription(_vm.newDescription)}}}),_c('Button',{staticClass:"p-button-danger",attrs:{"icon":"pi pi-times"},on:{"click":function($event){return _vm.cancelUpdateDescription()}}})],1):_c('div',[_c('label',{staticClass:"typo__label"},[_vm._v("Услуга активируется")])]),_c('div',{staticClass:"tariff-card__content"},[_c('div',{staticClass:"tariff-card__activity"},[_c('label',{staticClass:"typo__label"},[_vm._v(_vm._s(_vm.$t('actiovationflag.text')))]),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          autoHide: false,
          content: _vm.$t('actiovationflag.hint'),
          placement: 'top-start',
          container: 'body',
        }),expression:"{\n          autoHide: false,\n          content: $t('actiovationflag.hint'),\n          placement: 'top-start',\n          container: 'body',\n        }"}],staticClass:"amount-block__hint",attrs:{"icon":"help","size":"small","color":"dim","tabindex":"-1"}}),_c('div',{staticClass:"tariff-card__prolong"},[_c('tumbler',{staticClass:"theme-switcher__tumbler",attrs:{"value":_vm.tariff.actiovationflag},on:{"change":_vm.setActivationFlag}}),_c('main-label',{staticClass:"tariffs-table-item__status",attrs:{"color":_vm.tariff.actiovationflag === true ? 'success' : 'error',"theme":"plain"}},[_vm._v(" "+_vm._s(_vm.tariff.actiovationflag === true ? _vm.$t('on') : _vm.$t('off'))+" ")])],1)],1)]),_c('div',{staticClass:"tariff-card__content"},[_c('div',{staticClass:"tariff-card__activity"},[_c('div',{staticClass:"tariff-card__note note-text"},[_vm._v(" "+_vm._s(_vm.$t(`created`, { date: _vm.createdate }))+" ")]),_c('plain-button',{staticClass:"tariff-card__prolong",attrs:{"color":"primary","to":{
          name: _vm.tariff.status.key === 'active' ? 'Statistic' : 'StatisticOffline',
          params: { id: _vm.tariff.id },
        }}},[_vm._v(" "+_vm._s(_vm.$t('static'))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }