<template>
  <div class="tariffs-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        :checked="checkedList.includes(item.id)"
        @click-more="$emit('click-more', item)"
        @checked="onChecked(item.id, $event)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableRow from './TariffsTableRow';
//import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import NewPagination from '@/components/Pagination/NewPagination';

export default {
  name: 'TariffsTableStack',
  components: {
    BaseTable,
    TariffsTableRow,
    // BaseCheckbox,
    NewPagination,
  },
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        /*
        {
          key: 'check',
          style: {
            width: '44px',
          },
        },*/
        {
          key: 'server',
          label: this.$t('tableHead.server'),
          style: {
            width: '230px',
            maxWidth: '230px',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },

        {
          key: 'description',
          label: this.$t('tableHead.description'),
          style: {
            width: '130px',
            maxWidth: '130px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '50px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },

        {
          key: 'createdate',
          label: this.$t('tableHead.createdate'),
          style: {
            width: '50px',
          },
          sort: {
            prop: 'createdate',
            order: 'asc',
          },
        },
        {
          key: 'flag',
          // label: this.$t('tableHead.flag'),
          hint: '12121212',
          style: {
            width: '4rem',
          },
        },
        // {
        //   key: 'period',
        //   label: this.$t('tableHead.period'),
        //   style: {
        //     width: '134px',
        //   },
        // },
        // {
        //   key: 'cost',
        //   label: this.$t('tableHead.cost'),
        //   style: {
        //     width: '90px',
        //   },
        //   sort: {
        //     prop: 'cost',
        //     order: 'asc',
        //   },
        // },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '96px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          key: 'statistic',
          // label: this.$t('tableHead.statistic'),
          label: '',
          style: {
            width: '114px',
          },
        },
        // {
        //   key: 'more',
        //   label: this.$t('tableHead.more'),
        //   style: {
        //     width: '44px',
        //   },
        // },
      ],
      checkedList: [],
      checkedAll: false,
      stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST'];
    },
    sort() {
      return this.$store.state.moduleStack.sort;
    },
    paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },
  },
  watch: {
    checkedList: function (list) {
      if (list.length < this.dataset.length) {
        this.checkedAll = false;
      }
    },
    sort: {
      handler(val, old) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onChecked(id, val) {
      const index = this.checkedList.findIndex(i => i === id);
      // if val is true and is not in list
      if (val && !~index) this.checkedList.push(id);
      // if val is false and is in list
      else if (!val && ~index) this.checkedList.splice(index, 1);
      // else do nothing
    },
    onSelectAllChanged(val) {
      this.checkedAll = val;
      if (val) {
        this.dataset.forEach(item => {
          if (!this.checkedList.includes(item.id)) this.checkedList.push(item.id);
        });
      } else {
        this.checkedList = [];
      }
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_SORT', { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "server": "Тариф",
      "id": "ID",
      "description": "Описание",
      "createdate": "Создан",
      "flag": "Авто",
      "active": "Активен",
      "build": "Создается",
      "error": "Создан с ошибкой",
      "statistic": "Статистика и расходы",
      "period": "Период",
      "cost": "Цена",
      "more": "Меню",
      "status": "Статус"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 1.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
