<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <!-- <template #check>
      <base-checkbox :value="item.id" :model-value="checked" @change="$emit('checked', $event)" />
    </template> -->
    <template #server>
      <router-link
        v-if="item.status.code === 2"
        :to="{ name: 'tariffStack', params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ item.pricelist }}
      </router-link>
      <div v-else class="tariffs-table-item__ip">
        {{ item.pricelist }}
      </div>
    </template>
    <template #flag>
      <div>
        <tumbler
          v-tooltip="tooltip"
          :value="item.actiovationflag"
          class="theme-switcher__tumbler"
          @change="setActivationFlag"
        />
        <main-label
          v-tooltip="tooltip"
          :color="item.actiovationflag === true ? 'success' : 'error'"
          theme="plain"
          class="tariffs-table-item__status"
        >
          {{ item.actiovationflag === true ? $t('on') : $t('off') }}
        </main-label>
      </div>
    </template>
    <template #description>
      <div v-if="isActive === false && item.status.code !== 1" class="tariff-table__description">
        <div>
          {{ item.description_os }}
        </div>
        <plain-button
          v-tooltip="{
            content: item.description_os ? $t('change') : $t('add'),
            placement: 'right-start',
            container: 'body',
          }"
          :icon="icon"
          size="medium"
          color="dim"
          tabindex="-1"
          class="tariff-table__description-btn"
          @click="isActive = true"
        />
      </div>
      <div v-else-if="isActive" class="tariff-table__edit">
        <InputText v-model="newDescription" type="text" autofocus class="p-inputtext-sm" />
        <Button
          icon="pi pi-check"
          class="p-button-success p-button-sm"
          :disabled="newDescription === item.description_os"
          @click="updateDescription(newDescription)"
        />
        <Button
          icon="pi pi-times"
          class="p-button-danger p-button-sm"
          @click="cancelUpdateDescription()"
        />
      </div>
      <div v-else></div>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip">
        {{ item.id }}
      </div>
    </template>

    <template #createdate>
      <div class="tariffs-table-item__ip note-color">
        {{ $d(item.createdate, 'short') }}
      </div>
    </template>
    <template #period>
      <div class="tariffs-table-item__ip note-color">
        {{ item.expiredate }}
      </div>
    </template>
    <template #cost>
      <div class="tariffs-table-item__cost note-color">
        {{ $n(item.cost, 'currency', $i18n.locale) }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <server-context-menu
          slot="popover"
          :tariff="item"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
    <template #statistic>
      <router-link
        v-if="item.status.code === 2"
        :to="{ name: 'Statistic', params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ $t('statistic') }}
      </router-link>
      <router-link
        v-else
        :to="{ name: 'StatisticOffline', params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ $t('statistic') }}
      </router-link>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import ServerContextMenu from './ServerContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
import Tumbler from '@/components/BaseTumbler/Tumbler';
export default {
  name: 'TariffsTableRow',
  components: {
    BaseTableRow,
    MainLabel,
    //BaseCheckbox,
    ServerContextMenu,
    Tumbler,
    Button,
    InputText,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icon: 'edit',
      isActive: false,
      newDescription: '',
    };
  },
  computed: {
    tooltip() {
      return {
        content: this.$t('actiovationflag.hint'),
        placement: 'right-start',
        container: 'body',
      };
    },
    formattedEndDate() {
      return format(this.item.expiredate, 'dd.MM.yyyy');
    },
  },
  mounted() {
    this.newDescription = this.item.description_os;
  },
  methods: {
    getLocalizedSize(val, units) {
      const GB = 1024;
      let num = val;
      let unitKey = units === 'МиБ' ? 'mb' : units;
      if (unitKey === 'mb' && val >= GB) {
        unitKey = 'gb';
        num = num / GB;
      }
      return this.$t(unitKey, { num });
    },
    concatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
    async updateDescription(descr) {
      this.newDescription = descr;
      const params = {
        elid: this.item.id,
        description_os: descr,
      };
      this.$store
        .dispatch('moduleStack/updateTariff', params)
        .then(() => {
          this.showSuccessModal(this.$t('success'));
          this.isActive = false;
          this.fetchList();
        })
        .catch(e => {
          this.isActive = false;
          this.newDescription = this.item.description_os;
          this.showError(e);
        });
    },
    fetchList() {
      return this.$store.dispatch('moduleStack/fetchList');
    },
    setActivationFlag(value) {
      const params = {
        actiovationflag: value === true ? 'on' : 'off',
        elid: this.item.id,
      };
      this.$store
        .dispatch('moduleStack/updateTariff', params)
        .then(() => {
          // this.showSuccessModal(this.$t('actiovationflag.success'));
          this.fetchList();
        })
        .catch(e => {
          this.showError(e);
        });
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    cancelUpdateDescription() {
      this.isActive = false;
      this.newDescription = this.item.description_os;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "actiovationflag": {
      "text": "Автовключение услуги",
      "success": "Изменение прошло успешно",
      "hint": "Автоматическое включение услуги при положительном балансе"
    },
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "on": "Включено",
    "off": "Выключено",
    "success": "Описание изменено.",
    "statistic": "Статистика и расходы",
    "add": "Добавить описание",
    "change": "Изменить описание"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}

.tariff-table__description {
  display: flex;

  &-btn {
    margin-left: 0.75rem;
  }
}
.tariff-table__edit {
  display: flex;

  &-btn {
    margin-left: 0.75rem;
  }
}
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
